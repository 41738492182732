import React, { useEffect, useState, useRef } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  useEditableControls,
  useColorModeValue,
  ChakraProvider,
  Box,
  Text,
  Stack,
  HStack,
  Center,
  Code,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  Tooltip,
  IconButton,
  Flex,
  ButtonGroup,
  Input,
  Spacer,
  Badge,
  Button,
  Wrap,
  Circle,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
} from "@chakra-ui/react";
import {
  CloseIcon,
  CheckIcon,
  EditIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/acai.css";

function App() {
  // 2. Wrap ChakraProvider at the root of your app
  return (
    <ChakraProvider>
      <Home />
    </ChakraProvider>
  );
}

const replacer = (
  match: any,
  pIndent: any,
  pKey: any,
  pVal: any,
  pEnd: any
) => {
  var key = "<span class=json-key>";
  var val = "<span class=json-value>";
  var str = "<span class=json-string>";
  var r = pIndent || "";
  if (pKey) r = r + key + pKey.replace(/[": ]/g, "") + "</span>: ";
  if (pVal) r = r + (pVal[0] == '"' ? str : val) + pVal + "</span>";
  return r + (pEnd || "");
};
const prettyPrint = (obj: any) => {
  var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;
  return JSON.stringify(obj, null, 3)
    .replace(/&/g, "&amp;")
    .replace(/\\"/g, "&quot;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(jsonLine, replacer);
};

function CustomControlsExample({
  data,
}: // height
any) {
  /* Here's a custom control */
  const [height, setHeight] = useState(100);

  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();
  const ref: any = useRef(null);

  useEffect(() => {
    const value = ref.current?.clientHeight;
    if (value > 100 && value != height) {
      setHeight(ref.current.clientHeight);
    }
  });

  return (
    <Stack width="100%" maxW={"90vw"} pt={6} pl={1} {...getEditButtonProps()}>
      {/* <EditablePreview /> */}

      {/* Here is the custom input */}
      {/* <Code 
      // backgroundColor={"#2e2f38"}
      backgroundColor={"red"}
      p={0}
      m={0}
      width={600}
      > */}
      {!isEditing && (
        <Stack>
          <div ref={ref}>
            <JSONPretty
              style={{
                // fontSize: "1.0em",
                minWidth: 300,
                // backgroundColor: "#e2e8f0 !important",
                backgroundColor: "#2e2f38 !important",
                // overflowWrap: false
              }}
              id="json-pretty"
              data={data}
            ></JSONPretty>
          </div>
        </Stack>
      )}
      <pre style={{ backgroundColor: "#2e2f38", color: "#dcdff7" }}>
        <Box minW={300} height={height + 2} p={0} m={0} as={EditableTextarea} />
      </pre>
      {/* </Code> */}

      {/* <IconButton
        backgroundColor={"#2e2f38"}
        color={"#fff"}
        aria-label=""
        size="sm"
        icon={<EditIcon />}
        {...getEditButtonProps()}
      /> */}

      <Flex justifyContent="center">
        {!isEditing ? (
          <></>
        ) : (
          <HStack>
            <IconButton
              backgroundColor={"#2e2f38"}
              color={"#fff"}
              aria-label=""
              size="sm"
              icon={<CheckIcon />}
              {...getSubmitButtonProps()}
            />
            <IconButton
              backgroundColor={"#2e2f38"}
              color={"#fff"}
              aria-label=""
              size="sm"
              icon={<CloseIcon />}
              {...getCancelButtonProps()}
            />
          </HStack>
        )}
      </Flex>

      {/* 
      {isEditing ? (
        <ButtonGroup justifyContent="center" size="sm">
          <IconButton
            aria-label=""
            icon={<CheckIcon />}
            {...getSubmitButtonProps()}
          />
          <IconButton
            aria-label=""
            icon={<CloseIcon />}
            {...getCancelButtonProps()}
          />
        </ButtonGroup>
      ) : (
        <Flex justifyContent="center">
          <IconButton
            aria-label=""
            size="sm"
            icon={<EditIcon />}
            {...getEditButtonProps()}
          />
        </Flex>
      )} */}
    </Stack>
  );
}

const DealBox = ({ deal }: any) => {
  const fontColor = "#111";
  return (
    <Box
      // backgroundColor={"#2e2f38"}
      backgroundColor={"#fff"}
      borderRadius={12}
      overflow={"hidden"}
      boxShadow={"lg"}
      height={160}
      width={"100%"}
      p={4}
    >
      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Creator:
        </Text>
        <Text fontSize="sm" fontWeight="normal" color={fontColor}>
          {deal.associated}
        </Text>
      </HStack>

      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Full Payout:
        </Text>
        <Text fontSize="sm" fontWeight="normal" color={fontColor}>
          {`$${deal.target}`}
        </Text>
      </HStack>

      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Status:
        </Text>
        <Badge colorScheme="yellow">{deal.status}</Badge>
      </HStack>

      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Price:
        </Text>
        <Text fontSize="sm" fontWeight="normal" color={fontColor}>
          ${deal.price}
        </Text>
      </HStack>

      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Historical Prob:
        </Text>
        <Text fontSize="sm" fontWeight="normal" color={fontColor}>
          {deal.expectedProb}%
        </Text>
      </HStack>

      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Expires:
        </Text>
        <Text fontSize="sm" fontWeight="normal" color={fontColor}>
          {deal.expires}
        </Text>
      </HStack>
    </Box>
  );
};

const DataSourceBox = ({ datasource }: any) => {
  const fontColor = "#111";
  return (
    <Box
      // backgroundColor={"#2e2f38"}
      backgroundColor={"#fff"}
      borderRadius={12}
      overflow={"hidden"}
      boxShadow={"lg"}
      height={100}
      width={"100%"}
      p={4}
    >
      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Provider:
        </Text>
        <Text fontSize="sm" fontWeight="normal" color={fontColor}>
          {datasource.provider}
        </Text>
      </HStack>

      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Location:
        </Text>
        <Text
          fontSize="sm"
          fontWeight="normal"
          color={fontColor}
          onClick={() => {
            window.open(datasource.loc);
          }}
          cursor="pointer"
        >
          {datasource.loc}
        </Text>
      </HStack>

      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Pointer:
        </Text>
        <Text fontSize="sm" fontWeight="normal" color={fontColor}>
          <Code>{datasource.JSONPointer}</Code>
        </Text>
      </HStack>
    </Box>
  );
};

const RuleBox = ({ rule }: any) => {
  const fontColor = "#111";
  return (
    <Box
      // backgroundColor={"#2e2f38"}
      backgroundColor={"#fff"}
      borderRadius={12}
      overflow={"hidden"}
      boxShadow={"lg"}
      height={220}
      width={"100%"}
      p={4}
    >
      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Event if triggered:
        </Text>
        <Text fontSize="sm" fontWeight="normal" color={fontColor}>
          {rule?.event?.params?.message}
        </Text>
      </HStack>

      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Specifics
        </Text>
      </HStack>

      <Stack>
        {rule?.conditions &&
          rule.conditions.any.map((data: any) => {
            return (
              <Box
                color="#000"
                px={1}
                pt={1}
                mt={1}
                backgroundColor="#f2f6ff"
                borderRadius={4}
                borderWidth={1}
                // shadow="lg"
              >
                <Flex>
                  {data.all.map((brick: any) => {
                    return (
                      <Box
                        as={Box}
                        color="#000"
                        px={6}
                        py={3}
                        m={4}
                        backgroundColor="#fff"
                        borderWidth={1}
                        borderRadius={4}
                      >
                        <Text fontWeight={"semibold"}>
                          <Code color="#444">{brick.fact}</Code>
                          <Spacer />
                          <Code color="#444">{brick.operator}</Code>
                          <Spacer />
                          <Code color="#444">{brick.value}</Code>
                        </Text>
                      </Box>
                    );
                  })}
                </Flex>
              </Box>
            );
          })}
      </Stack>
    </Box>
  );
};

const DealBox2 = () => {
  const fontColor = "#111";
  return (
    <Box
      // backgroundColor={"#2e2f38"}
      backgroundColor={"#fff"}
      borderRadius={6}
      overflow={"hidden"}
      boxShadow={"lg"}
      height={200}
      width={"100%"}
      p={4}
    >
      <HStack>
        <Text fontSize="sm" fontWeight="semibold" color={fontColor}>
          Creator:
        </Text>
        <Text fontSize="sm" fontWeight="normal" color={fontColor}>
          0xdrbh
        </Text>
      </HStack>
    </Box>
  );
};

function Home() {
  const [datasource, setDateSource] = useState<string>(
    JSON.stringify(
      {
        provider: "FRED - stlouisfed",
        loc: "https://fred.stlouisfed.org/series/CPIAUCSL",
        url: "https://api.stlouisfed.org/fred/series/observations",
        method: "GET",
        JSONPointer: "$.observations[0]",
        params: {
          series_id: "CPIAUCSL",
          api_key: "05e8d7fed2842badd1c979b37cf5f55f",
          file_type: "json",
          sort_order: "desc",
          limit: "3",
        },
      },
      null,
      2
    )
  );
  const [rule, setRule] = useState<string>(
    JSON.stringify(
      {
        conditions: {
          any: [
            {
              all: [
                {
                  fact: "date",
                  operator: "equal",
                  value: "2022-07-01",
                },
                {
                  fact: "value",
                  operator: "greaterThanInclusive",
                  value: "296.271",
                },
              ],
            },
          ],
        },
        event: {
          type: "inflated",
          params: {
            message: "Inflation has happened.",
          },
        },
      },
      null,
      2
    )
  );

  const [deal, setDeal] = useState<string>(
    JSON.stringify(
      {
        id: "0x1",
        status: "proposed",
        target: 1000,
        associated: "drbh",
        expires: 10000000,
        commitments: [
          { user: "user1", amount: 10 },
          { user: "user2", amount: 20 },
        ],
        price: 2000,
        expectedProb: 0.01,
      },
      null,
      2
    )
  );

  const [parsedDataSource, setParsedDataSource] = useState<object>({});
  const [parsedRule, setParsedRule] = useState<object>({});
  const [parsedDeal, setParsedDeal] = useState<object>({});

  useEffect(() => {
    try {
      setParsedDataSource(JSON.parse(datasource));
    } catch (error) {
      console.log(error);
    }
  }, [datasource]);

  useEffect(() => {
    try {
      setParsedRule(JSON.parse(rule));
    } catch (error) {
      console.log(error);
    }
  }, [rule]);

  useEffect(() => {
    try {
      setParsedDeal(JSON.parse(deal));
    } catch (error) {
      console.log(error);
    }
  }, [deal]);

  const handleDataSourceEdit = (e: any) => {
    setDateSource(e);
  };

  const handleRuleEdit = (e: any) => {
    setRule(e);
  };

  const handleDealEdit = (e: any) => {
    setDeal(e);
  };

  return (
    <Center h="100%">
      <Stack
        // width={650}
        width={600}
        mt={100}
      >
        <Center>
          <Text fontSize="xl" fontWeight="bold">
            Example 1
          </Text>
        </Center>
        <Box
        // borderWidth={1}
        // mt={50}
        >
          <Stack p={5}>
            <HStack>
              <Stack width={"100%"}>
                <Text fontSize="sm" fontWeight="semibold" color="#8872cc88">
                  {"Recent"}
                </Text>
              </Stack>
            </HStack>

            <Wrap>
              {[...Array(24)].map((v: number) => (
                <Popover>
                  <PopoverTrigger>
                    <Circle
                      size={"24px"}
                      // backgroundColor="#50C878"
                      backgroundColor="none"
                      borderColor="#9c88db"
                      borderWidth={3}
                      shadow="md"
                    />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverHeader>Header</PopoverHeader>
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Button colorScheme="blue">Button</Button>
                      </PopoverBody>
                      <PopoverFooter>This is the footer</PopoverFooter>
                    </PopoverContent>
                  </Portal>
                </Popover>
              ))}
              <Text
                lineHeight={"25px"}
                fontSize="sm"
                fontWeight="semibold"
                color="#888"
              >
                09/05 00:43
              </Text>
            </Wrap>
            <br />

            <EditableStyledJson
              visual={<DealBox deal={parsedDeal} />}
              title={"Deal"}
              str={deal}
              handleEdit={handleDealEdit}
              parsed={parsedDeal}
            />

            {/* <DealBox2 />
            <br /> */}

            <EditableStyledJson
              visual={<DataSourceBox datasource={parsedDataSource} />}
              title={"Datasource"}
              str={datasource}
              handleEdit={handleDataSourceEdit}
              parsed={parsedDataSource}
            />

            <EditableStyledJson
              visual={<RuleBox rule={parsedRule} />}
              title={"Rule"}
              str={rule}
              handleEdit={handleRuleEdit}
              parsed={parsedRule}
            />
          </Stack>
        </Box>
      </Stack>
    </Center>
  );
}

const EditableStyledJson = ({
  visual,
  title,
  str,
  handleEdit,
  parsed,
  height,
}: any) => {
  const [showCode, setShowCode] = useState(false);
  const handleSetShowCode = (e: any) => setShowCode(!showCode);
  return (
    <Stack
      // mb={20}
      width="100%"
    >
      <HStack>
        <Stack width={"100%"}>
          <Text fontSize="sm" fontWeight="semibold" color="#8872cc88">
            {title}
          </Text>
        </Stack>
        <Box
          as={Stack}
          // backgroundColor={"#2e2f38"}
          // color={"#fff"}
          // variant="outline"
          // aria-label=""
          // size="sm"
          onClick={handleSetShowCode}
        >
          {showCode ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Box>
      </HStack>
      {visual}
      {showCode && (
        <>
          <Editable
            value={str}
            isPreviewFocusable={false}
            selectAllOnFocus={false}
            onChange={handleEdit}
            backgroundColor={"#2e2f38"}
            borderRadius={12}
            overflow={"hidden"}
            boxShadow="dark-lg"
          >
            <CustomControlsExample data={parsed} height={height} />
          </Editable>
        </>
      )}
      <div style={{ height: "10px" }}></div>
    </Stack>
  );
};

export default App;
